.text-tittle {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  text-transform: capitalize;
 font-weight:bold;
}

.container-data-sheet {
  height: 40px;
}

.cold-data-sheet {
  height: 110%;
  display: flex;
  align-items: center;
}

.span-valoration-list {
    width: 25px;
    height: 25px; 
    background-color: #cbe3fe; 
    border-radius: 25px;
}

.container-result-data-sheet {
    display: flex;
}

.text-tittle-list {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.text-list {
  font-family: "Montserrat", sans-serif;
  text-transform: lowercase;
}

.text-list-p {
  font-family: "Montserrat", sans-serif;
  text-transform: lowercase;
}

.contenedor-arry-descriptions {
  display: flex;
}

.dimension-container-arry-descriptions {
  width: 30%;
}

.resposive-prescription {
  display: none;
}

.response-movile-prescription {
  display: none;
}

.text-pdf-container {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: black;
}

.image-flecha-container {
  height: 25px;
  width: 25px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
}

.flecha-reponsive:hover {
  background-color: #2fe4af;
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.flecha-reponsive {
  background-color: #4ec6a3;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.style-flecha {
  flex-flow: row-reverse;
  display: flex;
  align-items: center;
}

.image-flecha-responsive {
  height: 35px;
}

.container-fecha-resposive {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-informatio-medicamento {
  height: 200px;
}

.separation-contenedor {
  height: 50px;
}

.contenedor-arry-descriptions-pdf {
  display: flex;
}

@media screen and (max-width: 1025px) {
  .container-informatio-medicamento {
    height: auto;
  }

  .image-flecha-responsive {
    margin-left: 16px;
    height: 20px;
  }

  .image-generic-medicament {
    height: 85%;
  }
}

@media screen and (max-width: 769px) {
  .container-informatio-medicamento {
    height: auto;
  }

  .style-title {
    margin-right: 12px;
  }

  .dimension-container-arry-descriptions {
    width: 100%;
  }

  .image-generic-medicament {
    height: 60%;
  }
}

@media screen and (max-width: 764px) {
  .image-generic-medicament {
    height: 150%;
  }

  .contenedor-arry-descriptions-pdf {
    display: flex;
  }

  .container-informatio-medicamento {
    height: auto;
  }

  .resposive-prescription {
    display: block;
    height: 50px;
  }

  .contenedor-arry-descriptions {
    display: contents;
  }

  .response-movile-prescription {
    display: block;
  }

  .separation-contenedor {
    height: 150px;
  }
}

@media screen and (max-width: 480px) {
  .image-generic-medicament {
    height: 150%;
  }

  .contenedor-arry-descriptions-pdf {
    display: flex;
  }

  .container-informatio-medicamento {
    height: auto;
  }

  .resposive-prescription {
    display: block;
    height: 50px;
  }

  .contenedor-arry-descriptions {
    display: contents;
  }

  .response-movile-prescription {
    display: block;
  }

  .separation-contenedor {
    height: 150px;
  }
}
