html,
body,
.home,
div#root {
  height: 100%;
}

/** HEADER  **/

div#header {
  float: right;
  height: 10%;
  width: 100%;
  text-align: right;
  display: table;
  flex: 2;
  padding: 10px;
  padding-bottom: 0px;
}

div#header img.logo-header {
  height: auto;
  width: 200px;
}

/*** HOME ***/

.home {
  display: flex;
  flex-direction: column;
}

.cols {
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  flex: 9;
}

.left-col {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  flex: 3 1;
}

.right-col {
  flex: 2 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/** SEARCH FORM **/

.search-form {
  flex: 0.5 1;
  padding: 5px;
  margin: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search-form .text {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: #9c9ebf;
  font-size: 150%;
  margin-bottom: 30px;
  font-weight: bold;
}

.search-form .arrow {
  height: 80px;
  background-repeat: no-repeat;
  position: absolute;
  width: 80px;
  background-size: contain;
  z-index: 0;
  text-align: right;
  right: 0px;
}
.search-form .form-control {
  box-shadow: 2px 2px 10px #d6d6d6;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

.form-control.intolerances-button {
  cursor: pointer;
}

/** FOOTER **/
.footer {
  flex: 1;
  text-align: center;
  margin-top: 20px;
}

.footer img {
  height: 40px;
  margin: 0px 10px 0px 10px;
}

.footer a {
  margin: 0px 10px 0px 10px;
  color: #000000;
}

/******* SEARCHER  ******/

.selectors {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.selectors .form-control {
  margin: 5px;
}

button.action-button {
  border-radius: 25px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 40px;
  margin-top: 2%;
  background-color: #58b6b8;
  color: #fff;
  border: none;
  transition: all linear 0.2s;
}

button.action-button:hover {
  background-color: #b32229;
}

button:focus {
  outline: 0px;
}

/******   POPUP INTOLERANCES   *****/

.popup-intolerances {
  background-color: #fff;
  border: solid 1px #17a2b8;
  border-radius: 2px;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  margin-top: -275px;
}

.popup-intolerances span {
  float: left;
  clear: both;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.popup-intolerances .list-intolerance-drop-down {
  float: left;
  background-color: #fff;
  padding: 1px;
  margin: 0px;
  clear: both;
}

.popup-intolerances .title-checkbox {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #000;
  margin-left: 5px;
}

/** VISTA RESULTADOSS  **/

.root.results-view .search-form .selectors {
  display: flex;
}

.root.results-view .search-form .selectors .form-control {
  flex: 1;
}

.root.results-view .results-view-container {
  margin-left: 10%;
  margin-right: 10%;
}

.root.results-view .search-form {
  background-color: whitesmoke;
  padding: 15px;
  margin-bottom: 20px;
}

.results-title span {
  color: #58b6b8;
  font-weight: bold;
  font-size: 13px;
}

.container-results-prescriptions {
  width: 24%;
  margin-bottom: 10px;
  float: left;
  margin: 0.5%;
}

.result-prescriptions {
  float: left;
  width: 100%;
  display: table;
}

.root.results-view .popup-intolerances {
  margin-top: 40px;
  margin-left: 47px;
}

/*CAJAS*/
button.box-finaly,
.image-flecha-container,
button.boxSecondary {
  transition: all linear 0.3s;
}
button.box-finaly:hover,
.image-flecha-container:hover,
button.boxSecondary:hover {
  opacity: 0.5;
}

img.face-image-box {
  width: 50px;
}

button.boxSecondary {
  font-weight: bold;
}

.style-text-title {
  font-family: "Montserrat", sans-serif;
 font-weight:bold;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
}

.style-text-title {
  font-family: "Montserrat", sans-serif;
 font-weight:bold;
  color: #fff;
  font-size: 14px;
  word-break: break-all;
}

.font-text {
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-top: 17px;
  margin-bottom: 17px;
  margin-left: 13px;
}

.box-finaly .font-text {
  margin-top: 13px;
  margin-bottom: 13px;
}

.title-checkbox {
    font-size: 11px;
    font-weight: 500;
}

.picky__input {
  cursor: pointer;
}  

.via-selector {
  cursor: pointer;
}

/** TOOLTIP **/
.MuiTooltip-tooltip {
  color: #fff;
  padding: 4px 8px;
  font-size: 14px !important;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
}

/**  DETAILS **/

.root .prescription-view,
.prescription-view-container {
  margin-left: 10%;
  margin-right: 10%;
  float: left;
  margin-bottom: 40px;
}

.prescription-view {
  margin-bottom: 40px;
}

.text-tittle {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  text-transform: capitalize;
 font-weight:bold;
  margin-left: 30px;
}

.img-data-sheet {
  height: 100%;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.excipient-way-active {
  display: flex;
  margin-top: 30px;
  float: left;
  clear: both;
  width: 100%;
}

.box-item {
  flex: 1;
  border: solid 1px #e8e8e8;
  padding: 10px;
  margin: 10px;
}

.box-item ul {
  margin: 0px;
  padding: 0px;
}

.box-item ul li {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
}

.note {
  padding: 10px;
  background: #e4626221;
  border: solid 1px #d6abab;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin-top: 20px;
}

.note strong {
  color: #ca3333;
}

.image-generic-medicament {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px !important;
  margin-top: 30px;
}

.item-info {
  float: left;
  width: 31%;
  padding: 10px;
  margin: 10px;
}

p.text-tittle-list strong {
  float: left;
  color: darkgrey;
}

p.text-tittle-list span {
  float: left;
  clear: both;
}

.text-tittle-list-data-sheet {
  color: black;
  font-size: 15px;
}

.downloads {
  float: left;
  width: 100%;
}

.downloads .head-separator {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.pdf-container {
  height: 100px;
  width: 120px;
  text-align: -webkit-center;
  float: left;
}

.downloads {
  float: left;
  width: 100%;
  padding: 10px;
  margin: 10px;
  background-color: #fbfbfb;
  border: solid 1px #f5f5f5;
}

/* NO RESULTS*/
.container-hidde.no-results {
  margin: auto;
}

.search-form .form-control {
  font-size: 13px;
}


/* MEDIA QUERIES */
@media (max-width: 640px) {
  .selectors {
    display: block;
  }

  .search-form .form-control {
    text-align-last: start;
  }  

  .picky {
    display: table-caption;
  }

  .left-col {
    display: none;
  }
  .container-results-prescriptions {
    width: 100% !important;
  }

  .root.results-view .search-form .selectors {
    display: block;
  }

  .root.results-view .search-form .selectors .form-control {
    width: calc(100% - 10px);
    float: left;
    text-align: left;
  }
}

@media (max-width: 480px) {
  div#header {
    float: none;
    height: 60px;
    width: 100%;
    text-align: center;
    display: table;
    flex: 2 1;
    padding: 10px;
    padding-bottom: 0px;
  }

  div#header img.logo-header {
    height: auto;
    height: 60px;
    width: auto;
  }
  .root.results-view .results-view-container {
    margin-left: 5%;
    margin-right: 5%;
  }

  .popup-intolerances {
    background-color: #fff;
    border: solid 1px #17a2b8;
    border-radius: 2px;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    margin: initial !important;
    width: 80%;
    top: 5%;
    left: 10%;
  }

  .root.results-view .search-form {
    background-color: whitesmoke;
    padding: 15px;
    margin: 5px;
    margin-bottom: 10px;
  }

  .results-title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .textTittle {
    font-size: 30px !important;
  }

  .container-hidde {
    width: 100% !important;
  }

  /*DETALLE*/

  .root .prescription-view,
  .prescription-view-container {
    margin-left: 5%;
    margin-right: 5%;
    float: left;
    margin-bottom: 40px;
  }

  .container-fecha-resposive {
    display: none !important;
  }

  .prescription-view .container-result-data-sheet {
    display: flex;
    margin-top: 30px;
  }

  .prescription-view .text-tittle {
    font-family: "Montserrat", sans-serif;
    font-size: 17px;
    text-transform: capitalize;
   font-weight:bold;
    margin-left: 0px;
    text-align: center;
  }

  .prescription-view .excipient-way-active {
    display: block;
    margin-top: 30px;
    float: left;
    clear: both;
    width: 100%;
  }

  .prescription-view .img-data-sheet {
    width: 50%;
    border-radius: 50%;
  }

  .note {
    font-size: 14px;
  }

  .item-info {
    width: 100%;
    margin: 0px;
  }

  .downloads {
    margin: 0px;
  }
}

.text-cinfa {
  color: #9c9ebf;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 13px;
}

.text-discalimer {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  color: #000000;
  text-align: center;
  width: 100%;
  margin-top: 8px
}

.footer {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.container-footer {
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.cmplz-slider-checkbox input:checked + .cmplz-slider {						background-color: #5ccebb					}					.cmplz-slider-checkbox input:focus + .cmplz-slider {						box-shadow: 0 0 1px #5ccebb;					}					.cmplz-slider-checkbox .cmplz-slider:before {						background-color: #fff;					}.cmplz-slider-checkbox .cmplz-slider-na:before {						color:#fff;					}					.cmplz-slider-checkbox .cmplz-slider {					    background-color: #f4a977;					}
#cc-window.cc-window .cmplz-categories-wrap .cc-check svg {stroke: #333}

figcaption {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-style: italic;
}